import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import firebase from 'firebase';

let idToken: string | undefined = undefined;
let dateAndTimeIdToken: Date = new Date();

const baseUrl: string = (window.location.href.indexOf("https://") !== -1 ? "https://" : "http://") + window.location.hostname + (process.env.NODE_ENV !== 'production' ? ':5000' : '');

const getRequestConfig = async (): Promise<AxiosRequestConfig> => {
    let diff = new Date().getTime() - dateAndTimeIdToken.getTime();

    let minutes = diff / 1000 / 60;

    if (minutes > 55) {
        idToken = undefined;
    }

    if (idToken === undefined) {
        idToken = await firebase.auth().currentUser?.getIdToken(true);
        dateAndTimeIdToken = new Date();
    }

    let config = {
        headers: {
            'Authorization': 'Bearer ' + idToken
        }
    } as AxiosRequestConfig

    // console.log('config', config);

    return config;
}

export const getCoordinatesCount = async (request: CalculateCoordinatesRequest): Promise<AxiosResponse<GetCoordinatesCountResponse>> => {
    try {
        const results: AxiosResponse<GetCoordinatesCountResponse> = await axios.post(baseUrl + "/api/get-coordinates-count", request, await getRequestConfig());

        return results;
    } catch (error) {
        throw new Error(error)
    }
}

export const getCalculatedCoordinates = async (request: CalculateCoordinatesRequest): Promise<AxiosResponse<CalculateCoordinatesResponse>> => {
    try {
        const results: AxiosResponse<CalculateCoordinatesResponse> = await axios.post(baseUrl + "/api/calculate-coordinates", request, await getRequestConfig());

        return results
    } catch (error) {
        throw new Error(error)
    }
}

export const getUsers = async (): Promise<AxiosResponse<GetUsersResponse>> => {
    try {
        const results: AxiosResponse<GetUsersResponse> = await axios.get(baseUrl + "/api/users", await getRequestConfig());

        return results
    } catch (error) {
        throw new Error(error)
    }
}

export const getUser = async (email: string): Promise<AxiosResponse<GetUserResponse>> => {
    try {
        const results: AxiosResponse<GetUserResponse> = await axios.get(baseUrl + "/api/users/" + email, await getRequestConfig());

        return results
    } catch (error) {
        throw new Error(error)
    }
}

export const addUser = async (email: string, isAdmin: boolean): Promise<AxiosResponse<AddUserResponse>> => {
    try {
        const results: AxiosResponse<AddUserResponse> = await axios.post(baseUrl + "/api/users", {
            email: email,
            isAdmin: isAdmin
        } as AddUserRequest, await getRequestConfig());

        return results;
    } catch (error) {
        throw new Error(error)
    }
}

export const updateUser = async (email: string, isAdmin: boolean): Promise<AxiosResponse<UpdateUserResponse>> => {
    try {
        const results: AxiosResponse<UpdateUserResponse> = await axios.put(baseUrl + "/api/users/" + email, {
            isAdmin: isAdmin
        } as UpdateUserRequest, await getRequestConfig());

        return results
    } catch (error) {
        throw new Error(error)
    }
}

export const deleteUser = async (email: string): Promise<AxiosResponse<DeleteUserResponse>> => {
    try {
        const results: AxiosResponse<DeleteUserResponse> = await axios.delete(baseUrl + "/api/users/" + email, await getRequestConfig());

        return results
    } catch (error) {
        throw new Error(error)
    }
}

export const getCategories = async (): Promise<AxiosResponse<GetCategoriesResponse>> => {
    try {
        const results: AxiosResponse<GetCategoriesResponse> = await axios.post(baseUrl + "/api/get-categories", {
        } as GetCategoriesRequest, await getRequestConfig());

        return results
    } catch (error) {
        throw new Error(error)
    }
}

export const getCities = async (cityFilter: string): Promise<AxiosResponse<GetCitiesResponse>> => {
    try {
        const results: AxiosResponse<GetCitiesResponse> = await axios.post(baseUrl + "/api/get-cities", {
            cityFilter: cityFilter
        } as GetCitiesRequest, await getRequestConfig());

        return results
    } catch (error) {
        throw new Error(error)
    }
}

export const getClassifications = async (categoryId: number, classificationFilter: string): Promise<AxiosResponse<GetClassificationsResponse>> => {
    try {
        const results: AxiosResponse<GetClassificationsResponse> = await axios.post(baseUrl + "/api/get-classifications", {
            categoryId: categoryId,
            classificationFilter: classificationFilter
        } as GetClassificationsRequest, await getRequestConfig());

        return results
    } catch (error) {
        throw new Error(error)
    }
}

export const importData = async (request: ImportDataRequest): Promise<AxiosResponse<ImportDataResponse>> => {
    try {
        const results: AxiosResponse<ImportDataResponse> = await axios.post(baseUrl + "/api/import-data", request, await getRequestConfig());

        return results;
    } catch (error) {
        throw new Error(error)
    }
}

export const checkReportsExists = async (): Promise<boolean> => {
    try {
        const results: AxiosResponse<CheckReportsExistsResponse> = await axios.get(baseUrl + "/api/check-reports-exists", await getRequestConfig());

        return results.data.reportsExists;
    } catch (error) {
        throw new Error(error)
    }
}

export const downloadReportsFile = async () => {
    let config = await getRequestConfig();
    config.url = baseUrl + '/api/download-reports';
    config.method = 'GET';
    config.responseType = 'blob';

    axios
        .request(config)
        .then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'reports.zip'); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(error => {
            console.log('Error', error);
        });
}

export const applyUpdates = async (applyUpdatesDoctors: boolean, applyUpdatesPharmacies: boolean, applyUpdatesBuprenorphineDoctors: boolean, applyUpdatesOpioidTreatmentDoctors: boolean) => {
    try {
        const results: AxiosResponse<ApplyUpdatesResponse> = await axios.post(baseUrl + "/api/apply-updates", {
            applyUpdatesDoctors: applyUpdatesDoctors,
            applyUpdatesPharmacies: applyUpdatesPharmacies,
            applyUpdatesBuprenorphineDoctors: applyUpdatesBuprenorphineDoctors,
            applyUpdatesOpioidTreatmentDoctors: applyUpdatesOpioidTreatmentDoctors
        } as ApplyUpdatesRequest, await getRequestConfig());

        // console.log('results', results);

        return results.data;
    } catch (error) {
        throw new Error(error)
    }
}