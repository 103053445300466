import { Dialog } from "primereact/components/dialog/Dialog";
import React, { useEffect, useState } from "react";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { ProgressSpinner } from 'primereact/progressspinner';
import { checkReportsExists } from '../../Services/Api';
import { isNull } from 'lodash';

type UploadUpdatesProps = {
    displayModal: boolean,
    setDisplayModal: (visible: boolean) => void,
    onImportData: (doctorsDatabaseUrl: string, pharmaciesDatabaseUrl: string, buprenorphineDoctorsDatabaseUrl: string, opioidTreatmentDatabaseUrl: string) => Promise<void>,
    onDownloadReports: () => Promise<void>;
    onApplyUpdates: (applyUpdatesDoctors: boolean, applyUpdatesPharmacies: boolean, applyUpdatesBuprenorphineDoctors: boolean, applyUpdatesOpioidTreatmentDoctors: boolean) => Promise<void>;
}

const UploadUpdates = ({ displayModal, setDisplayModal, onImportData, onDownloadReports, onApplyUpdates }: UploadUpdatesProps) => {
    const [doctorsDatabaseUrl, setDoctorsDatabaseUrl] = useState<string>('')
    const [doctorsDatabaseSkip, setDoctorsDatabaseSkip] = useState<boolean>(true)
    const [pharmaciesDatabaseUrl, setPharmaciesDatabaseUrl] = useState<string>('')
    const [pharmaciesDatabaseSkip, setPharmaciesDatabaseSkip] = useState<boolean>(true)

    const [buprenorphineDoctorsDatabaseUrl, setBuprenorphineDoctorsDatabaseUrl] = useState<string>('')
    const [buprenorphineDoctorsDatabaseSkip, setBuprenorphineDoctorsDatabaseSkip] = useState<boolean>(true)
    const [opioidTreatmentDatabaseUrl, setOpioidTreatmentDatabaseUrl] = useState<string>('')
    const [opioidTreatmentDatabaseSkip, setOpioidTreatmentDatabaseSkip] = useState<boolean>(true)

    const [loading, setLoading] = useState<boolean>(false)
    const [reportsAvailable, setReportsAvailable] = useState<boolean>(false)

    useEffect(() => {
        checkReportsExists()
            .then((data: any) => {
                let reportsAvailable = data as boolean;
                // console.log('reportsAvailable', reportsAvailable);
                setReportsAvailable(reportsAvailable)
            })
            .catch((err: Error) => console.log(err))
    }, []);

    const onImportDataInternal = async (doctorsDatabaseUrl: string, pharmaciesDatabaseUrl: string,
        buprenorphineDoctorsDatabaseUrl: string, opioidTreatmentDatabaseUrl: string) => {
        if (!isNull(doctorsDatabaseUrl) || !isNull(pharmaciesDatabaseUrl)) {
            try {
                setLoading(true);
                await onImportData(doctorsDatabaseUrl, pharmaciesDatabaseUrl, buprenorphineDoctorsDatabaseUrl, opioidTreatmentDatabaseUrl);
                setReportsAvailable(true);
            } catch (e) {
                console.log('Error', e);
            } finally {
                setLoading(false);
            }
        }
    }

    const onDownloadReportsInternal = async () => {
        try {
            setLoading(true);
            await onDownloadReports();
        }
        catch (e) {
            console.log('Error', e);
        } finally {
            setLoading(false);
        }
    }

    const onApplyUpdatesInternal = async () => {
        try {
            setLoading(true);
            await onApplyUpdates(!doctorsDatabaseSkip && !isNull(doctorsDatabaseUrl),
                !pharmaciesDatabaseSkip && !isNull(pharmaciesDatabaseUrl),
                !buprenorphineDoctorsDatabaseSkip && !isNull(buprenorphineDoctorsDatabaseUrl),
                !opioidTreatmentDatabaseSkip && !isNull(opioidTreatmentDatabaseUrl));
            setReportsAvailable(false);
        }
        catch (e) {
            console.log('Error', e);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <Dialog header="Upload updates" visible={displayModal} style={{ width: '900px' }} onHide={() => setDisplayModal(false)}>
                <div className='card'>
                    <div className="p-field p-grid p-jc-end">
                        <label htmlFor="doctorsDatabaseUrl" style={{ width: 150 }}>NPI Database Url</label>
                        <div className="p-col">
                            <InputText id="doctorsDatabaseUrl" type="text" value={doctorsDatabaseUrl} disabled={doctorsDatabaseSkip} onChange={(e) => {
                                setDoctorsDatabaseUrl(e.currentTarget.value)
                            }
                            } style={{ width: 600 }} />
                        </div>
                        <label htmlFor="doctorsDatabaseSkip" style={{ width: 50 }}>Skip</label>
                        <Checkbox id='doctorsDatabaseSkip' onChange={e => {
                            setDoctorsDatabaseSkip(e.checked);
                            setDoctorsDatabaseUrl('');
                        }
                        } checked={doctorsDatabaseSkip}></Checkbox>
                    </div>
                    <div className="p-field p-grid p-jc-end">
                        <label htmlFor="pharmaciesDatabaseUrl" style={{ width: 150 }}>NCPDP Database Url</label>
                        <div className="p-col">
                            <InputText id="pharmaciesDatabaseUrl" type="text" value={pharmaciesDatabaseUrl} disabled={pharmaciesDatabaseSkip} onChange={(e) => {
                                setPharmaciesDatabaseUrl(e.currentTarget.value);
                            }} style={{ width: 600 }} />
                        </div>
                        <label htmlFor="pharmaciesDatabaseSkip" style={{ width: 50 }}>Skip</label>
                        <Checkbox id='pharmaciesDatabaseSkip' onChange={e => {
                            setPharmaciesDatabaseSkip(e.checked);
                            setPharmaciesDatabaseUrl('');
                        }} checked={pharmaciesDatabaseSkip}></Checkbox>
                    </div>
                    <div className="p-field p-grid p-jc-end">
                        <label htmlFor="buprenorphineDoctorsDatabaseUrl" style={{ width: 150 }}>Buprenorphine Doctor Database Url</label>
                        <div className="p-col">
                            <InputText id="buprenorphineDoctorsDatabaseUrl" type="text" value={buprenorphineDoctorsDatabaseUrl} disabled={buprenorphineDoctorsDatabaseSkip} onChange={(e) => {
                                setBuprenorphineDoctorsDatabaseUrl(e.currentTarget.value);
                            }} style={{ width: 600 }} />
                        </div>
                        <label htmlFor="buprenorphineDoctorsDatabaseSkip" style={{ width: 50 }}>Skip</label>
                        <Checkbox id='buprenorphineDoctorsDatabaseSkip' onChange={e => {
                            setBuprenorphineDoctorsDatabaseSkip(e.checked);
                            setBuprenorphineDoctorsDatabaseUrl('');
                        }} checked={buprenorphineDoctorsDatabaseSkip}></Checkbox>
                    </div>

                    <div className="p-field p-grid p-jc-end">
                        <label htmlFor="opioidTreatmentDatabaseUrl" style={{ width: 150 }}>Opioid Treatment Database Url</label>
                        <div className="p-col">
                            <InputText id="opioidTreatmentDatabaseUrl" type="text" value={opioidTreatmentDatabaseUrl} disabled={opioidTreatmentDatabaseSkip} onChange={(e) => {
                                setOpioidTreatmentDatabaseUrl(e.currentTarget.value);
                            }} style={{ width: 600 }} />
                        </div>
                        <label htmlFor="opioidTreatmentDatabaseSkip" style={{ width: 50 }}>Skip</label>
                        <Checkbox id='opioidTreatmentDatabaseSkip' onChange={e => {
                            setOpioidTreatmentDatabaseSkip(e.checked);
                            setOpioidTreatmentDatabaseUrl('');
                        }} checked={opioidTreatmentDatabaseSkip}></Checkbox>
                    </div>

                    <div className="p-field p-grid p-jc-end p-mr-1 p-mt-5 p-mb-0">
                        {!loading ?
                            (
                                <div className='p-d-flex p-flex-row'>
                                    <Button type="button" label="Import Data" className='p-mr-3'
                                        onClick={() => onImportDataInternal(
                                            doctorsDatabaseSkip ? '' : doctorsDatabaseUrl,
                                            pharmaciesDatabaseSkip ? '' : pharmaciesDatabaseUrl,
                                            buprenorphineDoctorsDatabaseSkip ? '' : buprenorphineDoctorsDatabaseUrl,
                                            opioidTreatmentDatabaseSkip ? '' : opioidTreatmentDatabaseUrl
                                        )} />

                                    {
                                        reportsAvailable ?
                                            <div>
                                                <Button type="button" label="Download Reports" className='p-mr-3'
                                                    onClick={() => onDownloadReportsInternal()} />

                                                <Button type="button" label="Apply Updates"
                                                    onClick={() => onApplyUpdatesInternal()} />
                                            </div>

                                            :
                                            <></>
                                    }
                                </div>

                            ) :
                            (
                                <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="#EEEEEE" />
                            )
                        }
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default UploadUpdates;