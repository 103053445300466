import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyA15PBuyEu8JhujkzMXBsQm2rFjTQfGkFs",
    authDomain: "rx-ninja-293105.firebaseapp.com",
    databaseURL: "https://rx-ninja-293105.firebaseio.com",
    projectId: "rx-ninja-293105",
    storageBucket: "rx-ninja-293105.appspot.com",
    messagingSenderId: "574558587583",
    appId: "1:574558587583:web:c9b03640d90cd540c5d40a",
    measurementId: "G-D0ZYDJYG4Z"
};

firebase.initializeApp(firebaseConfig);