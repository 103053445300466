import React, { useCallback, useEffect, useRef, useState } from 'react';
import './App.css';
import GoogleMapComponent from './Components/Map/GoogleMapComponent';
import 'primeflex/primeflex.css';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Slider } from 'primereact/slider';
import { Button } from 'primereact/button';
import { applyUpdates, downloadReportsFile, getCalculatedCoordinates, getCategories, getCities, getClassifications, getCoordinatesCount, getUser, importData } from './Services/Api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as _ from "lodash";
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { TabView, TabPanel } from 'primereact/tabview';
import { CSVLink } from "react-csv";
import { Menubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/components/menuitem/MenuItem';
import { Menu } from 'primereact/components/menu/Menu';
import Users from './Components/Users/Users';
import Login from './Pages/Login';
import firebase from 'firebase';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import UploadUpdates from './Components/UploadUpdates/UploadUpdates';

const App = () => {
  const [firebaseUser, setFirebaseUser] = useState<firebase.User | null>(null)
  const [user, setUser] = useState<UserRow | undefined | null>(undefined)

  const [loadingSearch, setLoadingSearch] = useState<boolean>(false)

  const [categories, setCategories] = useState<CategoryRow[]>([])

  const [sourceCategory, setSourceCategory] = useState<CategoryRow>({} as CategoryRow)
  const [targetCategory, setTargetCategory] = useState<CategoryRow>({} as CategoryRow)

  const [maxDistance, setMaxDistance] = useState<number>(3)

  const [cityFilter, setCityFilter] = useState<string>('')
  const [cities, setCities] = useState<CityRow[]>([])
  const [selectedCities, setSelectedCities] = useState<CityRow[]>([])
  const cityFilterTimeout = useRef<number>();

  const [docClassificationFilter, setDocClassificationFilter] = useState<string>('')
  const [docClassifications, setDocClassifications] = useState<ClassificationRow[]>([])
  const [selectedDocClassifications, setDocSelectedClassifications] = useState<ClassificationRow[]>([])
  const docClassifictionFilterTimeout = useRef<number>();

  const [phaClassificationFilter, setPhaClassificationFilter] = useState<string>('')
  const [phaClassifications, setPhaClassifications] = useState<ClassificationRow[]>([])
  const [selectedPhaClassifications, setPhaSelectedClassifications] = useState<ClassificationRow[]>([])
  const phaClassifictionFilterTimeout = useRef<number>();

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState<boolean>(false)

  const [displayUsersModal, setDisplayUsersModal] = useState<boolean>(false)

  const [displayUploadUpdatesModal, setDisplayUploadUpdatesModal] = useState<boolean>(false)

  const [sourceCategoryCount, setSourceCategoryCount] = useState<number>(0)
  const [targetCategoryCount, setTargetCategoryCount] = useState<number>(0)
  const [sourceCategoryGoogleApiCount, setSourceCategoryGoogleApiCount] = useState<number>(0)
  const [targetCategoryGoogleApiCount, setTargetCategoryGoogleApiCount] = useState<number>(0)
  const [totalCategoryCount, setTotalCategoryCount] = useState<number>(0)
  const [totalCategoryGoogleApiCount, setTotalCategoryGoogleApiCount] = useState<number>(0)

  const [entities, setEntities] = useState<EntityRow[]>([])
  const [groups, setGroups] = useState<GroupEntities[]>([])
  const [showResults, setShowResults] = useState(false)
  const [resultExpandedRows, setResultExpandedRows] = useState<any[]>();

  const [exportFilename, setExportFilename] = useState<string>('')
  const [exportData, setExportData] = useState<object[]>([]);

  const dataTableRef = useRef<DataTable>(null);

  const menuRef = useRef<Menu>(null);

  const toastRef = useRef<Toast>(null);

  const fetchCategories = (): void => {
    getCategories()
      .then((data: any) => {
        let categories = data.data as CategoryRow[];

        // console.log('categories', categories);

        setCategories(categories);

        let sourceCategories = categories.filter(x => x.isSource);
        let targetCategories = categories.filter(x => x.isTarget);

        // console.log('sourceCategories', sourceCategories);
        // console.log('targetCategories', targetCategories);

        setSourceCategory(sourceCategories.filter(x => x.categoryId === 1)[0]);
        setTargetCategory(targetCategories.filter(x => x.categoryId === 2)[0]);
      })
      .catch((err: Error) => console.log(err))
  }

  useEffect(() => {
    setUser(undefined);
    // console.log('setUser(undefined);');
    // console.log('process.env.NODE_ENV', process.env.NODE_ENV);

    // console.log('Register => onAuthStateChanged');
    let unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      async (user) => {

        // console.log('onAuthStateChanged', user);

        setFirebaseUser(user);
        setUser(undefined);

        if (user) {
          getUser(user.email!)
            .then((data: any) => {
              let userRow = data.data as UserRow;

              if (userRow) {
                setUser(userRow);
                // console.log('setUser(userRow);');
                // console.log('onAuthStateChanged => getUser', userRow);
                fetchCategories();
              } else {
                setUser(null);
                // console.log('setUser(null);');
              }
            })
            .catch((err: Error) => console.log(err))
        } else {
          setUser(null);
          // console.log('setUser(null);');
        }

        // console.log('user', user);
      }
    );

    return () => {
      unregisterAuthObserver();
    }
  }, []);

  const fetchCities = useCallback((filter: string): void => {
    // console.log('fetchCities', filter, selectedCities);
    getCities(filter)
      .then((data: any) => {
        let cities = data.data as CityRow[];

        cities.forEach(c => c.key = c.cityId + '|' + c.stateId);

        // console.log('cities', cities, selectedCities);

        cities = cities.filter(c => selectedCities.findIndex(sc => sc.cityId === c.cityId) === -1);

        for (let i = selectedCities.length - 1; i >= 0; i--) {
          let sc = selectedCities[i];
          cities.unshift(sc);
        }

        setCities(cities);
      })
      .catch((err: Error) => console.log(err))
  }, [cityFilter, user]);

  const onCityFilterChange = (value: string): void => {
    // console.log('onCityFilterChange', value);
    setCityFilter(value);
  }

  useEffect(() => {
    if (user) {
      window.clearTimeout(cityFilterTimeout.current);
      cityFilterTimeout.current = window.setTimeout(() => {
        fetchCities(cityFilter);
        window.clearTimeout(cityFilterTimeout.current);
      }, 500);
    }
  }, [fetchCities, cityFilter, user]);

  const fetchDocClassifications = useCallback((filter: string): void => {
    // console.log('fetchDocClassifications', filter);
    getClassifications(1, filter) // 1 = Doctor
      .then((data: any) => {
        let docClassifications = data.data as ClassificationRow[];

        docClassifications = docClassifications.filter(dc => selectedDocClassifications.findIndex(sdc => sdc.classificationCode === dc.classificationCode) === -1);

        for (let i = selectedDocClassifications.length - 1; i >= 0; i--) {
          let sdc = selectedDocClassifications[i];
          docClassifications.unshift(sdc);
        }

        // console.log('docClassifications', docClassifications);
        setDocClassifications(docClassifications);
      })
      .catch((err: Error) => console.log(err))
  }, [docClassificationFilter, user]);

  const fetchPhaClassifications = (filter: string): void => {
    // console.log('fetchPhaClassifications', filter);
    getClassifications(2, filter) // 2 = Pharmacy
      .then((data: any) => {
        let phaClassifications = data.data as ClassificationRow[];

        // console.log('phaClassifications', phaClassifications);
        setPhaClassifications(phaClassifications);
      })
      .catch((err: Error) => console.log(err))
  }

  useEffect(() => {
    if (user) {
      window.clearTimeout(docClassifictionFilterTimeout.current);
      docClassifictionFilterTimeout.current = window.setTimeout(() => {
        fetchDocClassifications(docClassificationFilter);
        window.clearTimeout(docClassifictionFilterTimeout.current);
      }, 500);
    }
  }, [fetchDocClassifications, docClassificationFilter, user]);

  useEffect(() => {
    if (user) {
      window.clearTimeout(phaClassifictionFilterTimeout.current);
      phaClassifictionFilterTimeout.current = window.setTimeout(() => {
        fetchPhaClassifications(phaClassificationFilter);
        window.clearTimeout(phaClassifictionFilterTimeout.current);
      }, 500);
    }
  }, [phaClassificationFilter, user]);

  const fetchCalculatedCoordinates = (): void => {
    setLoadingSearch(true);

    getCalculatedCoordinates(getCalculateCoordinateRequest())
      .then((data: any) => {
        let response = data.data as CalculateCoordinatesResult;
        response.entities.forEach(ent => {
          ent.childrenCount = ent.children.length;
          ent.fullAddress = getFullAddress(ent);

          ent.children.forEach(child => {
            child.fullAddress = getFullAddress(child);
          });
        });

        // console.log('getCalculatedCoordinates', response);

        let allEntities: EntityRow[] = [];

        for (let i = 0; i < response.entities.length; i++) {
          allEntities.push(response.entities[i]);
        }

        for (let i = 0; i < response.entities.length; i++) {
          let target = response.entities[i];

          for (let j = 0; j < target.children.length; j++) {
            allEntities.push(target.children[j]);
          }
        }

        let groups: GroupEntities[] = [];

        // Get distinct list of entities
        allEntities = allEntities.filter((value, index, self) => self.findIndex(e => e.entityId === value.entityId && e.categoryId === value.categoryId) === index)

        fillGroups(groups, allEntities);

        // console.log('groups', groups);

        setGroups(groups);
        setEntities(response.entities);

        if (toastRef && toastRef.current) {
          if (response.entities.length > 0) {
            toastRef.current.show({ severity: 'info', summary: 'Completed', detail: `${response.entities.length} ${targetCategory.categoryPlural} found!` });
          }
          else {
            toastRef.current.show({ severity: 'warn', summary: 'Completed', detail: `No ${targetCategory.categoryPlural} found!` });
          }
        }

        // console.log('response.entities', response.entities);

        if (response.entities.length > 0) {
          setShowResults(true);
          console.log('response.entities', response.entities);
          fillExportCSV(response.entities);
        }

        setLoadingSearch(false);
      })
      .catch((err: Error) => {
        setLoadingSearch(false);
        console.log(err)
      })
  }

  const fillExportCSV = (entitiesToExport: EntityRow[]) => {
    let csvData: string[][] = [];

    csvData.push(['Source Category', 'Target Category', 'Cities', 'Classifications', 'Max Distance (Miles)']);
    csvData.push([sourceCategory.category, targetCategory.category, selectedCities.map(c => c.city).join(','), selectedDocClassifications.map(c => c.classificationName).join(','), (maxDistance / 100).toString()]);
    csvData.push(['', '']);

    csvData.push([targetCategory.entityCodeLabel, 'Name', 'City', 'State', 'Phone', 'Email', 'Count']);

    for (let i = 0; i < entitiesToExport.length; i++) {
      let entity = entitiesToExport[i];
      csvData.push([entity.entityCode, entity.name, entity.city, entity.state, entity.phone, entity.email, entity.childrenCount.toString()]);
    }

    // console.log('csvData', csvData);

    setExportData(csvData);
    let now = new Date()
    setExportFilename(`Export_${now.toLocaleDateString() + '-' + now.toLocaleTimeString()}.csv`);
  }

  const getCalculateCoordinateRequest = (): CalculateCoordinatesRequest => {
    // console.log('sourceCategory', sourceCategory);
    // console.log('targetCategory', targetCategory);

    // console.log('selectedDocClassifications', selectedDocClassifications);
    // console.log('selectedPhaClassifications', selectedPhaClassifications);

    let docClassificationCodes = selectedDocClassifications.map(x => x.classificationCode);
    let phaClassificationCodes = selectedPhaClassifications.map(x => x.classificationCode);

    let cityIds = selectedCities.filter(x => x.cityId !== 0).map(x => x.cityId);
    let stateIds = [0];

    if (cityIds.length === 0) {
      cityIds = [0];
      stateIds = selectedCities.filter(x => x.cityId === 0).map(x => x.stateId);
    }

    return {
      cityIds: cityIds,
      stateIds: stateIds,
      sourceCategoryId: sourceCategory.categoryId,
      targetCategoryId: targetCategory.categoryId,
      docClassificationCodes: docClassificationCodes,
      phaClassificationCodes: phaClassificationCodes,
      maxDistance: maxDistance / 100
    } as CalculateCoordinatesRequest;
  }

  const onSearchClick = () => {
    // console.log('selectedCities', selectedCities);
    // console.log('selectedClassifications', selectedClassifications);

    setLoadingSearch(true);

    getCoordinatesCount(getCalculateCoordinateRequest())
      .then((data: any) => {
        // console.log('data', data);

        let countResponse = data.data;

        setSourceCategoryCount(countResponse.sourceRowsCount);
        setSourceCategoryGoogleApiCount(countResponse.sourceRowsGoogleApiCount);
        setTargetCategoryCount(countResponse.targetRowsCount);
        setTargetCategoryGoogleApiCount(countResponse.targetRowsGoogleApiCount);
        setTotalCategoryCount(countResponse.sourceRowsCount + countResponse.targetRowsCount);
        setTotalCategoryGoogleApiCount(countResponse.sourceRowsGoogleApiCount + countResponse.targetRowsGoogleApiCount);

        setLoadingSearch(false);

        setDisplayConfirmationModal(true);
      })
      .catch((err: Error) => {
        setLoadingSearch(false);
        console.log(err)
      });
  }

  const renderFooterConfirmModal = () => {
    return (
      <div>
        <Button label="No" icon="pi pi-times" onClick={() => setDisplayConfirmationModal(false)} className="p-button-text" />
        <Button label="Yes" icon="pi pi-check" onClick={() => {
          setDisplayConfirmationModal(false);
          fetchCalculatedCoordinates();
        }} autoFocus />
      </div>
    );
  }

  const rowExpansionTemplate = (data: EntityRow) => {
    return (
      <div>
        <span><b>{data.name}</b> {data.address} {data.city} ({data.state})</span>
        <DataTable className="p-mt-3" value={data.children}>
          <Column field="entityCode" header={sourceCategory.entityCodeLabel} style={{ width: '20%', textOverflow: 'ellipsis', overflow: 'hidden' }}></Column>
          <Column field="name" header="Name" style={{ width: '30%' }}></Column>
          <Column field="fullAddress" header="Address" style={{ width: '50%' }}></Column>
        </DataTable>
      </div>
    );
  }

  const items: MenuItem[] = [
    {
      label: 'Users',
      icon: 'pi pi-user',
      disabled: !user?.isAdmin,
      command: () => {
        setDisplayUsersModal(true);
      }
    },
    {
      label: 'Upload updates',
      icon: 'pi pi-upload',
      disabled: !user?.isAdmin,
      command: () => {
        setDisplayUploadUpdatesModal(true);
      }
    },
    {
      label: 'Sign out',
      icon: 'pi pi-sign-out',
      command: (e) => {
        signOut();
      }
    }
  ];

  const signOut = () => {
    firebase.auth().signOut();
    setUser(undefined);
    console.log('setUser(undefined);');
  }

  const start = () => (
    <div className="p-d-inline-flex p-ai-center">
      <Button icon="pi pi-bars" className="p-button-rounded p-button-text p-mr-2" onClick={(event) => menuRef.current?.toggle(event)} aria-controls="popup_menu" aria-haspopup />
      <img alt="logo" src="logo192.png" height="40" className="p-mr-2"></img>
      <p className="p-ml-2">{user ? user.displayName : ''}</p>
    </div>
  )

  const onImportData = async (npiDatabaseUrl: string, ncpdpDatabaseUrl: string, buprenorphineDoctorsDatabaseUrl: string, opioidTreatmentDatabaseUrl: string) => {
    // console.log('npiDatabaseUrl', npiDatabaseUrl);
    // console.log('ncpdpDatabaseUrl', ncpdpDatabaseUrl);
    // console.log('buprenorphineDoctorsDatabaseUrl', buprenorphineDoctorsDatabaseUrl);
    // console.log('opioidTreatmentDatabaseUrl', opioidTreatmentDatabaseUrl);

    try {
      await importData({
        doctorsUrl: npiDatabaseUrl,
        pharmaciesUrl: ncpdpDatabaseUrl,
        buprenorphineDoctorsDatabaseUrl: buprenorphineDoctorsDatabaseUrl,
        opioidTreatmentDatabaseUrl: opioidTreatmentDatabaseUrl,
      } as ImportDataRequest);

      if (toastRef && toastRef.current) {
        toastRef.current.show({ severity: 'success', summary: 'Completed', detail: `Import Data completed!` });
      }
    } catch (e) {
      if (toastRef && toastRef.current) {
        toastRef.current.show({ severity: 'error', summary: 'Error', detail: `Error during import data!` });
      }

      throw e;
    }
  }

  const onDownloadReports = async () => {
    try {
      await downloadReportsFile();
    } catch (e) {
      if (toastRef && toastRef.current) {
        toastRef.current.show({ severity: 'error', summary: 'Error', detail: `Error during download reports!` });
      }

      throw e;
    }
  }

  const onApplyUpdates = async (applyUpdatesDoctors: boolean, applyUpdatesPharmacies: boolean, applyUpdatesBuprenorphineDoctors: boolean, applyUpdatesOpioidTreatmentDoctors: boolean) => {
    try {
      await applyUpdates(applyUpdatesDoctors, applyUpdatesPharmacies, applyUpdatesBuprenorphineDoctors, applyUpdatesOpioidTreatmentDoctors);

      if (toastRef && toastRef.current) {
        toastRef.current.show({ severity: 'success', summary: 'Completed', detail: `Apply updates completed!` });
      }
    } catch (e) {
      if (toastRef && toastRef.current) {
        toastRef.current.show({ severity: 'error', summary: 'Error', detail: `Error during apply updates!` });
      }

      throw e;
    }
  }

  const countEntitiesBodyTemplate = (city: CityRow) => {
    return (
      <span>{city.countEntities.toLocaleString('en')}</span>
    )
  }

  return (
    user === undefined ?
      <div className="p-d-flex p-jc-center">
        <div className="p-d-flex p-flex-column p-pt-3">
          <h2 className="p-m-2 p-p-2 p-as-center">Loading...</h2>
          <ProgressBar mode="indeterminate" style={{ width: '500px' }} />
        </div>
      </div>
      :
      user === null ?
        <Login firebaseUser={firebaseUser} signOut={signOut} />
        :
        <div className="App">
          <div className="p-grid p-m-0">
            <div className="p-col-4">
              <div className="p-grid p-dir-col" >
                <div className="card">
                  <Menubar start={start} />
                  <Menu model={items} popup ref={menuRef} id="popup_menu" />
                </div>
                <TabView >
                  <TabPanel header="Main">
                    <div className="p-fluid p-formgrid p-grid p-pt-3">
                      <div className="p-field p-col">
                        <label>Source Category</label>
                        <Dropdown optionLabel="category" value={sourceCategory} options={categories.filter(x => x.isSource)} onChange={(e) => { setSourceCategory(e.value) }} placeholder="Select the source category" />
                      </div>
                      <div className="p-field p-col">
                        <label>Target Category</label>
                        <Dropdown optionLabel="category" value={targetCategory} options={categories.filter(x => x.isTarget)} onChange={(e) => { setTargetCategory(e.value) }} placeholder="Select the target category" />
                      </div>
                    </div>
                    <div className="p-fluid p-pt-6">
                      <div className="p-field">
                        <label>Max Distance (Miles) {maxDistance / 100}</label>
                        <Slider value={maxDistance} min={1} max={500} step={1} onChange={(e) => setMaxDistance(Number(e.value))} />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel header="Cities">
                    <div className="p-fluid">
                      <div className="p-field">
                        <span className="p-input-icon-left">
                          <i className="pi pi-search" />
                          <InputText value={cityFilter} onChange={(e) => onCityFilterChange(e.currentTarget.value)} placeholder="Search city / state" />
                        </span>
                        <br />
                        <DataTable value={cities} selection={selectedCities} onSelectionChange={e => setSelectedCities(e.value)} dataKey="key" paginator
                          paginatorTemplate="PageLinks"
                          rows={11}>
                          <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                          <Column field="city" header="City" style={{ width: '50%' }}></Column>
                          <Column field="state" header="State" style={{ width: '30%' }}></Column>
                          <Column field="countEntities" header="Count" body={countEntitiesBodyTemplate} style={{ width: '20%' }}></Column>
                        </DataTable>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel header="Doctors (Class.)">
                    <div className="p-fluid">
                      <div className="p-field">
                        <span className="p-input-icon-left">
                          <i className="pi pi-search" />
                          <InputText value={docClassificationFilter} onChange={(e) => setDocClassificationFilter(e.currentTarget.value)} placeholder="Search classification" />
                        </span>
                        <br />
                        <DataTable value={docClassifications} selection={selectedDocClassifications} onSelectionChange={e => setDocSelectedClassifications(e.value)} dataKey="classificationCode" paginator
                          paginatorTemplate="PageLinks"
                          rows={11}>
                          <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                          <Column field="classificationName" header="Classification" style={{ width: '50%' }}></Column>
                          <Column field="specializationName" header="Specialization" style={{ width: '30%' }}></Column>
                          <Column field="countEntities" header="Count" style={{ width: '20%' }}></Column>
                        </DataTable>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel header="Pharmacies (Class.)">
                    <div className="p-fluid">
                      <div className="p-field">
                        <span className="p-input-icon-left">
                          <i className="pi pi-search" />
                          <InputText value={phaClassificationFilter} onChange={(e) => setPhaClassificationFilter(e.currentTarget.value)} placeholder="Search classification" />
                        </span>
                        <br />
                        <DataTable value={phaClassifications} selection={selectedPhaClassifications} onSelectionChange={e => setPhaSelectedClassifications(e.value)} dataKey="classificationCode" paginator
                          paginatorTemplate="PageLinks"
                          rows={8}>
                          <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                          <Column field="classificationName" header="Classification" style={{ width: '50%' }}></Column>
                          <Column field="specializationName" header="Specialization" style={{ width: '30%' }}></Column>
                          <Column field="countEntities" header="Count" style={{ width: '20%' }}></Column>
                        </DataTable>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel header="Result">
                    <div className="p-fluid">
                      {showResults ?
                        (
                          <div className="p-field">
                            <DataTable value={entities} dataKey="entityId" paginator
                              paginatorTemplate="PageLinks" expandedRows={resultExpandedRows} onRowToggle={(e) => setResultExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate}
                              rows={8} ref={dataTableRef}>
                              <Column expander style={{ width: '3em' }} />
                              <Column field="entityCode" header={targetCategory.entityCodeLabel} style={{ width: '20%' }}></Column>
                              <Column field="name" header="Name" style={{ width: '60%' }}></Column>
                              <Column field="childrenCount" header="Count" style={{ width: '20%' }}></Column>
                            </DataTable>
                            <div >
                              <CSVLink data={exportData} style={{ textDecoration: 'none' }} filename={exportFilename}>
                                <Button type="button" className="p-button-warning" icon="pi pi-external-link" iconPos="right" label="Export"></Button>
                              </CSVLink>
                            </div>
                            <div>
                            </div>
                          </div>
                        ) :
                        (
                          <>
                          </>
                        )
                      }
                    </div>

                    <div className="p-fluid">
                      <div className="p-field">
                        {!loadingSearch ?
                          (
                            <Button label="Search" icon="pi pi-search" iconPos="right" onClick={() => onSearchClick()} />
                          ) :
                          (
                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="#EEEEEE" />
                          )
                        }
                      </div>
                    </div>
                  </TabPanel>
                </TabView>
                <Toast ref={toastRef} />
              </div>

              <Dialog header="Confirmation" visible={displayConfirmationModal} modal style={{ width: '450px' }} footer={renderFooterConfirmModal()} onHide={() => setDisplayConfirmationModal(false)}>
                <div className="confirmation-content">
                  <div className="p-text-left">{sourceCategory.category} : {sourceCategoryCount} addresses (Google Api calls {sourceCategoryGoogleApiCount})</div>
                  <div className="p-text-left">{targetCategory.category} : {targetCategoryCount} addresses (Google Api calls {targetCategoryGoogleApiCount})</div>
                  <br />
                  <div className="p-text-left">Total : {totalCategoryCount} (Google Api calls {totalCategoryGoogleApiCount})</div>
                  <hr />
                  <span className="p-text-left">Are you sure you want to proceed?</span>
                </div>
              </Dialog>

              <Users displayModal={displayUsersModal} setDisplayModal={setDisplayUsersModal} user={user} />

              <UploadUpdates displayModal={displayUploadUpdatesModal} setDisplayModal={setDisplayUploadUpdatesModal}
                onImportData={onImportData} onDownloadReports={onDownloadReports} onApplyUpdates={onApplyUpdates} />
            </div>
            <div className="p-col-8">
              <GoogleMapComponent groups={groups} />
            </div>
          </div>
        </div >
  );
}

export default App;


function fillGroups(groups: GroupEntities[], entities: EntityRow[]) {
  let tempGroups = _.groupBy(entities, (entity) => {
    return entity.latitude + '|' + entity.longitude;
  });

  for (let p in tempGroups) {
    // console.log('p', p);
    let values = p.split('|');
    let latitude = Number(values[0]);
    let longitude = Number(values[1]);

    groups.push({
      latitude: latitude,
      longitude: longitude,
      entities: tempGroups[p]
    } as GroupEntities);
  }
}

function getFullAddress(entity: EntityRow): string {
  return `${entity.address} ${entity.city} (${entity.state})`;
}

