import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import { } from '../../index';
import _ from 'lodash';

const GoogleMapComponent = (props: any) => {
    const { groups } = props;

    let availableColors = ['blue', 'green', 'red'];

    const [map, setMap] = useState<any>();

    const [center] = useState({ lat: 32, lng: -84 });
    const [zoom] = useState(11);

    useEffect(() => {
        // console.log('useEffect:groups', groups);
        // console.log('useEffect:_map', map);

        if (map && groups && groups.length > 0) {
            fitAllMarkers(groups);
        }
    })

    const getMarkerColor = (row: GroupEntities): string => {
        let categoryId = 0;

        let categoryIds = _.uniq(row.entities.map(x => x.categoryId));

        if (categoryIds.length === 1)
            categoryId = categoryIds[0];

        if (categoryId === 1)
            return availableColors[0];
        else if (categoryId === 2)
            return availableColors[1];
        else
            return availableColors[2];
    }

    const fitAllMarkers = (results: EntityRow[]) => {
        // console.log('fitAllMarkers', map);

        if (map) {
            const bounds = new google.maps.LatLngBounds();

            // console.log('handleApiLoaded', results);

            results.forEach(row => {
                bounds.extend(new google.maps.LatLng(row.latitude, row.longitude));
            });

            // console.log('handleApiLoaded', bounds);

            map.fitBounds(bounds);
        }
    }

    return (
        <div style={{ height: '98vh', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyARCiXc1DReLFfvVG8KTzXVbbALEA2zOKc' }}
                defaultCenter={center}
                defaultZoom={zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => setMap(map)}
            >
                {
                    groups.map((group: GroupEntities) => (
                        <Marker key={group.latitude + '|' + group.longitude}
                            lat={group.latitude}
                            lng={group.longitude}
                            group={group}
                            color={getMarkerColor(group)}
                        />
                    ))
                }
            </GoogleMapReact>
        </div>
    )
}

export default GoogleMapComponent;