import React, { SyntheticEvent, useRef } from 'react';
import './Marker.css';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const Marker = (props: any) => {
    const { color, group } = props;
    const overlayPanelRef = useRef<OverlayPanel>(null);

    const showInfo = (e: Event) => {
        let event = {
            nativeEvent: e,
            target: e.target
        } as React.SyntheticEvent<Element, Event>

        overlayPanelRef.current?.show(event, event.currentTarget);
    }

    return (
        <div>
            <OverlayPanel ref={overlayPanelRef} showCloseIcon className="overlay-panel-marker" style={{ width: '600px' }}>
                <DataTable value={group.entities} dataKey="entityId" paginator
                    paginatorTemplate="PageLinks"
                    rows={5}>
                    <Column field="name" header="Name" style={{ width: '30%' }}></Column>
                    <Column field="address" header="Address" style={{ width: '40%' }}></Column>
                    <Column field="city" header="City" style={{ width: '20%' }}></Column>
                    <Column field="state" header="State" style={{ width: '10%' }}></Column>
                </DataTable>
            </OverlayPanel>
            <div
                className="pin bounce"
                style={{ backgroundColor: color, cursor: 'pointer' }}
                title={'Test'}
                onClick={(e) => showInfo(e.nativeEvent)}
            />
            <div className="pulse" />
            <div className={group.entities.length > 99 ? 'marker-count marker-count-3-digit' : group.entities.length > 9 ? 'marker-count marker-count-2-digit' : 'marker-count'} onClick={(e) => showInfo(e.nativeEvent)}> {group.entities.length}</div>
        </div>
    );
};

export default Marker;